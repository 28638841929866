import type {
  ContentCardLocation,
  ContentItemDetails,
  ContentItemSource,
  SpecialAccordionLocation,
} from 'src/constants/types';
import { SubscriptionFormSource } from 'src/features/adminDashboard';
import type { User } from 'src/features/auth/types';
import { DICSource } from 'src/features/dic';
import { type DrugItemSource } from 'src/features/drugs';
import type { HandoutItemSource } from 'src/features/handouts';
import { SearchContentTypeFilter } from 'src/features/search/enums';
import type { StudentVerficationStatus } from 'src/features/studentVerification/types';
import { isWeb } from 'src/helpers/common';
import { isDxTx, isClinicalHandout, isDrugHandout, isDrug } from 'src/helpers/content';
import { Route } from 'src/navigation';

import { MixpanelService } from '../services';
import {
  ClinicalHandoutTOCParams,
  DrugHandoutTOCParams,
  DrugTOCParams,
  DxTxTOCParams,
  MixpanelContentBaseLocation,
  MixpanelContentSublocation,
  MixpanelDICLocation,
  MixpanelDrugLocation,
  MixpanelFavoriteLocation,
  MixpanelQuickLinkLocationParams,
  MixpanelSearchLocation,
  MixpanelSearchType,
  MixpanelStudentVerificationStatus,
  MixpanelUpgradeToProLocation,
} from '../types';

export const getMixpanelFavoritesLocation = (location: ContentCardLocation): MixpanelFavoriteLocation => {
  switch (location) {
    case 'content-item':
      return 'Single Item Page';
    case 'search-list':
      return 'Search Results Page';
    case 'recently-viewed':
      return 'Recently Viewed';
    case 'top-viewed':
      return 'Top Viewed';
    case 'content-list':
    default:
      return 'List Page';
  }
};

export const getMixpanelSearchLocationByRoute = (route: Route): MixpanelSearchLocation => {
  switch (route) {
    case Route.DrugsList:
    case Route.DrugItem:
    case Route.AppendixItem:
      return 'Drug Monographs Page';
    case Route.ClinicalHandoutsList:
      return 'Pet Owner Guides Page';
    case Route.DxTxList:
    case Route.DxTxItem:
      return 'Clinical Briefs Page';
    case Route.Algorithms:
      return 'Algorithms Page';
    case Route.Home:
    default:
      return 'Homepage';
  }
};

/** For native only */
export const getMixpanelSearchLocationByFilter = (
  filter: SearchContentTypeFilter,
): MixpanelSearchLocation => {
  switch (filter) {
    case SearchContentTypeFilter.Drug:
      return 'Drug Monographs Page';
    case SearchContentTypeFilter.Handout:
      return 'Pet Owner Guides Page';
    case SearchContentTypeFilter.DxTx:
      return 'Clinical Briefs Page';
    case SearchContentTypeFilter.Algorithm:
      return 'Algorithms Page';
    default:
      return 'Homepage';
  }
};

export const getMixpanelSearchType = (filter: SearchContentTypeFilter): MixpanelSearchType => {
  switch (filter) {
    case SearchContentTypeFilter.Drug:
      return 'Drug Monographs';
    case SearchContentTypeFilter.Algorithm:
      return 'Algorithms';
    case SearchContentTypeFilter.DxTx:
      return 'Clinical Briefs';
    case SearchContentTypeFilter.Handout:
      return 'Pet Owner Guides';
    case SearchContentTypeFilter.All:
    default:
      return 'All Content';
  }
};

export const getMixpanelQuickLinkLocation = (
  location: ContentCardLocation,
  content: 'drug' | 'dx-tx',
): MixpanelQuickLinkLocationParams | undefined => {
  const isDrug = content === 'drug';

  const listLocation = isDrug ? 'List of Monographs' : 'List of Clinical Briefs';

  switch (location) {
    case 'content-item':
      return { Location: isDrug ? 'Top of Monograph' : 'Top of Clinical Brief' };
    case 'content-list':
      return { Location: listLocation };
    case 'search-list':
      return { Location: 'Search Results Page' };
    case 'recently-viewed':
      return { Location: 'Recently Viewed' };
    case 'top-viewed':
      return { Location: 'Top Viewed' };
    case 'content-list-favorites':
      return { Location: listLocation, 'Sub-Location': 'Favorites' };
    case 'content-list-new':
      return { Location: listLocation, 'Sub-Location': 'New' };
    case 'content-list-updated':
      return { Location: listLocation, 'Sub-Location': 'Updated' };
  }
};

interface MixpanelLocationAndSubLocation<T extends string> {
  Location: MixpanelContentBaseLocation | T;
  'Sub-Location'?: MixpanelContentSublocation;
}

const getMixpanelContentBaseLocation = <T extends string>(
  contentItemSource: ContentItemSource | undefined,
  defaultLocation: T | MixpanelContentBaseLocation,
): MixpanelLocationAndSubLocation<T> => {
  switch (contentItemSource) {
    case 'home-screen-favorites':
      return { Location: 'My Favorites' };
    case 'notes':
      return { Location: 'Notes' };
    case 'search':
      return { Location: 'Search Results' };
    case 'recently-viewed':
      return { Location: 'Recently Viewed' };
    case 'top-viewed':
      return { Location: 'Top Viewed' };
    case 'content-list-favorites':
      return { Location: defaultLocation, 'Sub-Location': 'Favorites' };
    case 'content-list-new':
      return { Location: defaultLocation, 'Sub-Location': 'New' };
    case 'content-list-updated':
      return { Location: defaultLocation, 'Sub-Location': 'Updated' };
    default:
      return { Location: defaultLocation };
  }
};

export const getMixpanelCBLocation = (contentItemSource?: ContentItemSource) => {
  return getMixpanelContentBaseLocation(contentItemSource, 'Clinical Brief Landing Page');
};

export const getMixpanelAppendixLocation = (contentItemSource?: ContentItemSource) => {
  return getMixpanelContentBaseLocation(contentItemSource, 'Drugs Monographs Page');
};

export const getMixpanelDDxLocation = (contentItemSource?: ContentItemSource) => {
  return getMixpanelContentBaseLocation(contentItemSource, 'DDx Page');
};

export const getMixpanelDrugLocation = (
  source?: DrugItemSource,
): MixpanelLocationAndSubLocation<MixpanelDrugLocation> => {
  if (source === 'interaction-checker') {
    return { Location: 'Interaction Checker' };
  }

  return getMixpanelContentBaseLocation(source, 'Drugs Monographs Page');
};

export const getMixpanelAlgorithmLocation = (
  contentCardLocation?: ContentCardLocation | SpecialAccordionLocation,
): MixpanelLocationAndSubLocation<'Algorithms Page'> => {
  switch (contentCardLocation) {
    case 'search-list':
      return { Location: 'Search Results' };
    case 'recently-viewed':
      return { Location: 'Recently Viewed' };
    case 'top-viewed':
      return { Location: 'Top Viewed' };
    case 'content-list-favorites':
      return { Location: 'Algorithms Page', 'Sub-Location': 'Favorites' };
    case 'content-list-new':
      return { Location: 'Algorithms Page', 'Sub-Location': 'New' };
    case 'content-list-updated':
      return { Location: 'Algorithms Page', 'Sub-Location': 'Updated' };
    default:
      return { Location: 'Algorithms Page' };
  }
};

const getMixpanelHandoutsBaseLocation = <T extends string>(
  source: HandoutItemSource | undefined,
  defaultLocation: T,
): MixpanelLocationAndSubLocation<T> => {
  switch (source) {
    case 'search':
      return { Location: 'Search Results' };
    case 'recently-viewed':
      return { Location: 'Recently Viewed' };
    case 'top-viewed':
      return { Location: 'Top Viewed' };
    case 'home-screen-favorites':
      return { Location: 'My Favorites' };
    case 'content-list-favorites':
      return { Location: defaultLocation, 'Sub-Location': 'Favorites' };
    case 'content-list-new':
      return { Location: defaultLocation, 'Sub-Location': 'New' };
    case 'content-list-updated':
      return { Location: defaultLocation, 'Sub-Location': 'Updated' };
    default:
      return { Location: defaultLocation };
  }
};

export const getMixpanelClinicalHandoutLocation = (source?: HandoutItemSource) => {
  if (source === 'dx-tx-page') {
    return { Location: 'Clinical Briefs Page' } as const;
  }
  return getMixpanelHandoutsBaseLocation(source, 'Pet Owner Guides Page');
};

export const getMixpanelDrugHandoutLocation = (source?: HandoutItemSource) => {
  if (source === 'drug-page') {
    return { Location: 'Monograph Page' } as const;
  }
  return getMixpanelHandoutsBaseLocation(source, 'Pet Owner Guides Page');
};

export const getMixpanelDICLocation = (source?: DICSource): MixpanelDICLocation => {
  if (source === 'drug') {
    return 'Monograph Page';
  }

  return 'Features Navigation Bar';
};

export const getMixpanelTOCParams = (
  contentItemParams: Pick<ContentItemDetails, 'id' | 'contentType' | 'title'>,
  linkTitle: string,
) => {
  const contentTypeObj = { contentType: contentItemParams.contentType };
  if (isDrug(contentTypeObj)) {
    return {
      'Content Type': 'Drug',
      'Drug ID': contentItemParams.id,
      'Drug Title': contentItemParams.title,
      'ToC Topic Clicked': linkTitle,
    } as DrugTOCParams;
  }

  if (isDxTx(contentTypeObj)) {
    return {
      'Content Type': 'Dx&Tx',
      'Dx&Tx ID': contentItemParams.id,
      'Dx&Tx Title': contentItemParams.title,
      'ToC Topic Clicked': linkTitle,
    } as DxTxTOCParams;
  }

  if (isDrugHandout(contentTypeObj)) {
    return {
      'Content Type': 'Drug Handout',
      'Drug Handout ID': contentItemParams.id,
      'Drug Handout Title': contentItemParams.title,
      'ToC Topic Clicked': linkTitle,
    } as DrugHandoutTOCParams;
  }

  if (isClinicalHandout(contentTypeObj)) {
    return {
      'Content Type': 'Clinical Handout',
      'Clinical Handout ID': contentItemParams.id,
      'Clinical Handout Title': contentItemParams.title,
      'ToC Topic Clicked': linkTitle,
    } as ClinicalHandoutTOCParams;
  }
};

export const getMixpanelUpgradeToProLocation = (
  source?: SubscriptionFormSource,
): MixpanelUpgradeToProLocation => {
  switch (source) {
    case 'plan-benefits':
      return 'Plan Benefits';
    case 'plan-type':
    default:
      return 'Plan Type';
  }
};

export const getMixpanelStudentVerificationStatus = (
  status: StudentVerficationStatus,
): MixpanelStudentVerificationStatus => {
  switch (status) {
    case 'docUpload':
      return 'Documents Upload';
    case 'pending':
      return 'Documents Verification Pending';
    case 'sso':
      return 'SSO';
    case 'ssoPending':
      return 'SSO Pending';
    case 'failed':
      return 'Failed';
    case 'success':
      return 'Success';
    case 'emailLoop':
      return 'Email Loop';
    case 'expired':
      return 'Expired';
    case 'started':
      return 'Started';
  }
};

type MixpanelUser = Pick<User, 'isSharedAccount' | 'ip' | 'id' | 'mixpanelProperties' | 'createdAt'>;

const getUTMLastTouchTags = () => {
  if (!isWeb) {
    return {};
  }

  const sp = new URLSearchParams(window.location.search);
  return Object.fromEntries(
    [...sp.entries()]
      .filter(([key]) => key.match(new RegExp(/^utm_\w/, 'i')))
      .map(([key, value]) => [`${key} [last touch]`, value]),
  );
};

export const identifyUserInMixpanel = (user: MixpanelUser) => {
  const { isSharedAccount, ip, id, createdAt, mixpanelProperties } = user;
  const { groupId, groupKey, planCode, subscriptionState, userType, accessLevel } =
    mixpanelProperties || {};

  const utmTags = getUTMLastTouchTags();

  MixpanelService.identify(isSharedAccount ? ip || id : id);
  MixpanelService.setUserProperties({}); // this allows Mixpanel to set some default properties in user profile

  MixpanelService.setSuperProperties({
    ...utmTags,
    'Account Created Date': createdAt,
    'Subscription Status': subscriptionState || undefined,
    'User Type': userType,
    'Plan Code': planCode || undefined,
    'Access Level': accessLevel || undefined,
  });

  if (groupKey) {
    if (groupId) {
      MixpanelService.setGroup(groupKey, groupId);
    } else {
      MixpanelService.unsetGroup(groupKey);
    }
  }
};
