import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Route, RouteProp } from 'src/navigation';
import { useRoute } from 'src/navigation/hooks/useRoute';

import { useHandoutItem } from './queries';
import { handoutsSelector } from '../state';

export const useHandoutsToShare = () => {
  const { params } = useRoute<RouteProp<Route.ShareHandout>>();

  const { selectedHandouts, singleHandoutLanguage } = useSelector(handoutsSelector);
  const singleHandoutId = params?.handout;

  const { data: singleHandoutData, error, isLoading, fetchStatus } = useHandoutItem(singleHandoutId);
  const singleHandoutMode = !!singleHandoutId;

  useEffect(() => {
    /**
     * If a single handout is selected, we need to set the language of the handout
     */
    if (singleHandoutData) {
      singleHandoutData.language = singleHandoutLanguage;
    }
  }, [singleHandoutLanguage, singleHandoutData]);

  return {
    isLoading,
    fetchStatus,
    error,
    handouts: singleHandoutId ? (singleHandoutData ? [singleHandoutData] : []) : selectedHandouts,
    singleHandoutMode,
  };
};
