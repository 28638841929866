import type {
  ContentItemBase,
  ContentType,
  ContentItemSource,
  ContentWithSectionsItemParams,
  ContentItemWithSectionsDetailsBase,
} from 'src/constants/types';

export type ClinicalHandoutContentType =
  | ContentType.HandoutProcedure
  | ContentType.HandoutVaccine
  | ContentType.HandoutClinicalConditions
  | ContentType.HandoutMisc;

export type DrugHandoutContentType = ContentType.DrugHandout | ContentType.VeterinaryMedicationGuide;

export enum HandoutsLanguageType {
  English = 'english',
  Spanish = 'spanish',
  SpanishEnglish = 'spanish-english',
}
interface ClinicalHandoutBase {
  alsoCalled: string[];
  pdfUrl: string;
  hasSpanishVersion: boolean;
  language: HandoutsLanguageType;
}

export interface ClinicalHandout extends ContentItemBase, ClinicalHandoutBase {
  contentType: ClinicalHandoutContentType;
}

export interface ClinicalHandoutDetails extends ContentItemWithSectionsDetailsBase, ClinicalHandoutBase {
  contentType: ClinicalHandoutContentType;
  intro?: string;
}

interface DrugHandoutBase {
  pronunciation: string;
  description: string;
  hasSpanishVersion: boolean;
  language: HandoutsLanguageType;
  otherNamesForThisMedication?: string;
  otherCommonNames?: string;
  pdfUrl: string | null;
  commonDosageForms?: string;
}

export interface DrugHandout extends ContentItemBase, DrugHandoutBase {
  contentType: DrugHandoutContentType;
}

export interface DrugHandoutDetails extends ContentItemWithSectionsDetailsBase, DrugHandoutBase {
  contentType: DrugHandoutContentType;
  intro?: string;
}

export type Handout = DrugHandout | ClinicalHandout;

export interface HandoutToPrintOrEmail {
  id: string;
  title: string;
  contentType: ContentType;
}

export interface EmailHandoutsParams {
  emailsTo: string;
  emailsCc: string;
  emailsBcc: string;
  emailContent: string;
  additionalNotes: string;
  guideIds: string[];
  subject: string;
}

export interface GenerateHandoutsPDFParams {
  guideIds: string[];
  additionalNotes: string;
}

export type GenerateHandoutsPDFResponse = string;

export type HandoutItemSource = ContentItemSource | 'drug-page' | 'dx-tx-page';

export interface PrintableHandoutsListParams {
  items: Handout[];
}

export interface EmailHandoutsFormValues {
  to?: string;
  confirmTo?: string;
  cc?: string;
  confirmCC?: string;
  bcc?: string;
  confirmBCC?: string;
  emailNote: string;
  subject: string;
  ccCheckbox: boolean;
  bccCheckbox: boolean;
}

export type HandoutItemParams = Omit<ContentWithSectionsItemParams, 'source'> & {
  source?: HandoutItemSource;
};

export interface ShareHandoutScreenParams {
  handout: string;
}

export interface ShareHandoutsFormProps {
  singleHandoutData?: Handout;
  onShareSuccess?(): void;
}

export type SelectedHandout = Pick<
  Handout,
  'id' | 'title' | 'contentType' | 'hasSpanishVersion' | 'language'
>;
